import * as React from 'react'

import {Link} from 'gatsby'

// styles
const pageStyles = {
  backgroundColor: '#efeade',
  color: '#232129',
  padding: '200px 96px',
  fontFamily: '-apple-system, Roboto, sans-serif, serif',
  minHeight: '80vh'
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320
}

const paragraphStyles = {
  marginBottom: 48
}

// markup
const NotFoundPage = () => {
  return (
    <main style={pageStyles}>
      <h1 style={headingStyles}>Page introuvable.</h1>
      <p style={paragraphStyles}>
        <span role="img" aria-label="Pensive emoji">
          😔
        </span>{' '}
        La page que vous cherchez est introuvable.
        <br />
        <br />
        <Link to="/">Accueil</Link>
      </p>
    </main>
  )
}

export default NotFoundPage
